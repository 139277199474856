// Portfolio.js
import React from 'react';
import { Typography, Grid, Container } from '@mui/material/';
import { makeStyles } from '@mui/styles';
import logo1 from "../../src/Art/samplelogo2.jpg";
import logo2 from "../../src/Art/samplelogo3.png";
import logo3 from "../../src/Art/samplelogo4.jpg";
import logo4 from "../../src/Art/samplelogo4.png";

const useStyles = makeStyles((theme) => ({
    section: {
        // padding: theme.spacing(4),
    },
    logo: {
        maxWidth: 200,
        margin: 'auto',
    },
}));

function Portfolio() {
    const classes = useStyles();

    return (
        <section className={classes.section}>
            <Container>
                <Typography variant="h4" align="center" gutterBottom>Our Clients</Typography>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={6} sm={4} md={3}>
                        <img src={logo1} alt="Client Logo 1" className={classes.logo} />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <img src={logo2} alt="Client Logo 2" className={classes.logo} />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <img src={logo3} alt="Client Logo 3" className={classes.logo} />
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <img src={logo4} alt="Client Logo 4" className={classes.logo} />
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}

export default Portfolio;
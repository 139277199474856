import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const AnimatedBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
    padding: theme.spacing(6, 2),
    opacity: 0,
    transform: 'translateY(50px)',
    transition: 'opacity 0.8s ease, transform 0.8s ease',
}));

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    opacity: 0,
    transform: 'translateY(20px)',
    transition: 'opacity 0.8s ease 0.2s, transform 0.8s ease 0.2s',
}));

const SlidingText = () => {
    const boxRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    boxRef.current.style.opacity = 1;
                    boxRef.current.style.transform = 'translateY(0)';
                    boxRef.current.querySelector('p').style.opacity = 1;
                    boxRef.current.querySelector('p').style.transform = 'translateY(0)';
                }
            },
            { threshold: 0.1 }
        );

        if (boxRef.current) {
            observer.observe(boxRef.current);
        }

        return () => {
            if (boxRef.current) {
                observer.unobserve(boxRef.current);
            }
        };
    }, []);

    return (
        <AnimatedBox ref={boxRef}>
            <AnimatedTypography variant="h6" align="center">
                <p>
                    With a legacy of over 30+ Years in Powering Industries with Top Talent,
                    we have been dedicated to Launching Successful Careers, Aligning Leaders
                    with Cutting-Edge Opportunities.
                </p>
            </AnimatedTypography>
        </AnimatedBox>
    );
};

export default SlidingText;
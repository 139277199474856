import React from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SocialIcons from "./SocialIcons";

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: "auto",
        bottom: 0,
    },
    toolbar: {
        justifyContent: "center",
        backgroundColor: "white",
    },
    credits: {
        color: "#000",
    },
}));

const CreditsAppBar = () => {
    const classes = useStyles();

    return (
        <AppBar position="static" className={classes.appBar}>
            <Box
                sx={{ display: "flex", justifyContent: "space-between" }}
                className={classes.toolbar}
            >
                <Toolbar>
                    <Typography variant="body1" className={classes.credits}>
                        © {new Date().getFullYear()} Phoenix Consultant. All
                        rights reserved.
                    </Typography>
                </Toolbar>
                <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                    className={classes.appBar}
                >
                    <Typography
                        variant="body1"
                        className={classes.credits}
                        sx={{ alignContent: "center" }}
                    >
                        Site by Nitesh Sharma
                    </Typography>
                    <SocialIcons
                        linkedin
                        linkedinUsername="in/nitesh-sharma-dev"
                    />
                </div>
            </Box>
        </AppBar>
    );
};

export default CreditsAppBar;

import React, { useState } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const AnimatedButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
        backgroundColor: '#333',
    },
    '& .arrow': {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        transition: 'all 0.3s ease',
    },
    '& .arrow-left': {
        left: '-20px',
    },
    '& .arrow-right': {
        right: '-20px',
    },
    '&:hover .arrow-left': {
        left: '10px',
    },
    '&.clicked .arrow-right': {
        right: '10px',
    },
}));

const CustomButton = ({ children }) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 300);
    };

    return (
        <AnimatedButton
            variant="contained"
            onClick={handleClick}
            className={isClicked ? 'clicked' : ''}
        >
            <span className="arrow arrow-left">→</span>
            {children}
            <span className="arrow arrow-right">→</span>
        </AnimatedButton>
    );
};

export default CustomButton;
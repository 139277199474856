import React, { useState } from "react";
import { TextField, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import emailjs from "emailjs-com";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: 2,
    },
    submitButton: {
        marginTop: 2,
    },
}));

const LookingForTalent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [formData, setFormData] = useState({
        name: "",
        companyName: "",
        contactNo: "",
        email: "",
        currentRole: "",
        companyLocation: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.init("BkfOMMxkSBMGpRDdA");
        emailjs
            .send("service_l9nrfyj", "template_w797mmg", formData)
            .then((result) => {
                console.log("Email sent successfully:", result.text);
                // Reset form fields after successful submission
                setFormData({
                    name: "",
                    companyName: "",
                    contactNo: "",
                    email: "",
                    currentRole: "",
                    companyLocation: "",
                });
            })
            .then(() => {
                enqueueSnackbar("Form Submitted", { variant: "success" });
            })
            .catch((error) => {
                console.error("Error sending email:", error);
            });
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">Looking for Talent</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="Company Name"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="Contact No."
                        name="contactNo"
                        value={formData.contactNo}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="Current Role"
                        name="currentRole"
                        value={formData.currentRole}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="Company Location"
                        name="companyLocation"
                        value={formData.companyLocation}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        className={classes.submitButton}
                        sx={{
                            backgroundColor: "black",
                            "&:hover": { backgroundColor: "#EC4300" },
                        }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default LookingForTalent;

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./navbar.css";
import { Card, CardActionArea, CardMedia } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../src/Art/logo3.png";

export default function NavBar() {
    let navigate = useNavigate();
    const navItems = ["Home", "About", "Services", "Portfolio", "Contact"];
    let handleHomeClick = () => {
        navigate("/");
    };

    const handleAboutClick = () => {
        navigate("/about-us");
    };

    const handlePortfolioClick = () => {
        navigate("/portfolio");
    };

    const handleServicesClick = () => {
        navigate("/services");
    };

    const handleContactClick = () => {
        navigate("/contact");
    };

    return (
        <AppBar
            position="fixed"
            sx={{ backgroundColor: "transparent" }}
            elevation={0}
        >
            {/* <AppBar position="static" color='transparent' elevation={0}> */}
            <Toolbar>
                <Card
                    elevation={0}
                    sx={{
                        backgroundColor: "transparent", // for transparency
                    }}
                >
                    <CardActionArea
                        onClick={() => {
                            navigate("/");
                        }}
                        sx={{
                            padding: {
                                xs: "0.1rem",
                                sm: "0.2rem",
                                md: "0.3rem",
                                lg: "0.4rem",
                                xl: "0.5rem",
                            },
                            "&:hover": {
                                backgroundColor: "#00000055",
                                color: "white",
                            },
                        }}
                    >
                        <CardMedia
                            component="img"
                            src={logo}
                            sx={{
                                backgroundSize: "contain",
                                backgroundColor: "transparent",
                                height: {
                                    xs: "0.5rem",
                                    sm: "2rem",
                                    md: "2rem",
                                    lg: "3rem",
                                    xl: "3rem",
                                },
                                width: {
                                    xs: "2.5rem",
                                    sm: "10rem",
                                    md: "10rem",
                                    lg: "15rem",
                                    xl: "15rem",
                                },
                                zIndex: 1,
                                display: {
                                    xs: "none",
                                    sm: "none",
                                    md: "block",
                                    lg: "block",
                                    xl: "block",
                                },
                            }}
                            elevation={1}
                        />
                    </CardActionArea>
                </Card>
                <Typography
                    sx={{
                        display: "block",
                        flexGrow: 1,
                        color: "#EC4300",
                    }}
                ></Typography>
                <Button
                    onClick={handleHomeClick}
                    sx={{
                        color: "#fff",
                        margin: {
                            xs: 0.05,
                            sm: 0.08,
                            md: 0.1,
                            lg: 0.5,
                            xl: 1,
                        },
                        "&:hover": { backgroundColor: "#EC4300" },
                    }}
                >
                    Home
                </Button>
                <Button
                    onClick={handleAboutClick}
                    sx={{
                        color: "#fff",
                        margin: {
                            xs: 0.05,
                            sm: 0.08,
                            md: 0.1,
                            lg: 0.5,
                            xl: 1,
                        },
                        "&:hover": { backgroundColor: "#EC4300" },
                    }}
                >
                    About
                </Button>
                <Button
                    onClick={handlePortfolioClick}
                    sx={{
                        color: "#fff",
                        margin: {
                            xs: 0.05,
                            sm: 0.08,
                            md: 0.1,
                            lg: 0.5,
                            xl: 1,
                        },
                        "&:hover": { backgroundColor: "#EC4300" },
                    }}
                >
                    Portfolio
                </Button>
                <Button
                    onClick={handleServicesClick}
                    sx={{
                        color: "#fff",
                        margin: {
                            xs: 0.05,
                            sm: 0.08,
                            md: 0.1,
                            lg: 0.5,
                            xl: 1,
                        },
                        "&:hover": { backgroundColor: "#EC4300" },
                    }}
                >
                    Industry Focus
                </Button>
                <Button
                    onClick={handleContactClick}
                    sx={{
                        color: "#fff",
                        margin: {
                            xs: 0.05,
                            sm: 0.08,
                            md: 0.1,
                            lg: 0.5,
                            xl: 1,
                        },
                        "&:hover": { backgroundColor: "#EC4300" },
                    }}
                >
                    Contact
                </Button>
            </Toolbar>
        </AppBar>
    );
}

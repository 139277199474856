import React from "react";
import { Box, Typography, Container } from "@mui/material";

const AboutPC = () => {
    return (
        <Container
            maxWidth="md"
            sx={{ color: "white", backgroundColor: "black", padding: 10 }}
        >
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    About Us
                </Typography>
                <Typography variant="body1" paragraph>
                    Phoenix Consultant stands as a premier provider of strategic
                    recruitment solutions, with a legacy of excellence spanning
                    over two decades. Established in 1992, we have earned our
                    reputation as a trusted leader in the recruitment industry,
                    dedicated to connecting top-tier talent with leading
                    organizations.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom mt={4}>
                    Our Leadership
                </Typography>
                <Typography variant="body1" paragraph>
                    Phoenix Consultant was founded by Mr. Pravin Ithape, a
                    seasoned professional with over 30 years of experience in
                    the recruitment industry. His vision was to establish a
                    consultancy that not only meets the high demand for skilled
                    talent but also plays a pivotal role in the growth and
                    success of the industries we serve. Under his leadership,
                    Phoenix Consultant has become synonymous with quality,
                    integrity, and exceptional service in the recruitment
                    sector.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom mt={4}>
                    Our Mission
                </Typography>
                <Typography variant="body1" paragraph>
                    At Phoenix Consultant, our mission is to deliver exceptional
                    talent aligned with your business objectives. We specialize
                    in sourcing and placing skilled professionals across a
                    diverse range of sectors, including Electronics, Automation,
                    Mechanical, Automobile, Ancillary, Financial, Engineering,
                    Pharmaceutical, Chemical, Food, Construction, and
                    Management. Our deep industry expertise and extensive
                    network enable us to identify and deliver candidates who
                    drive success.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom mt={4}>
                    Our Expertise
                </Typography>
                <Typography variant="body1" paragraph>
                    With over 26 years of experience, we have successfully
                    facilitated the placement of more than 10,000 white-collar
                    professionals in leading manufacturing companies across
                    India. Our robust database of highly qualified candidates
                    spans multiple disciplines, ensuring we meet the unique and
                    varied needs of our clients. Our services extend beyond
                    placement to encompass a comprehensive suite of recruitment
                    solutions, including, direct placement, entry-level
                    recruitment, and executive search. We provide rigorous
                    pre-screening, reference checks, background verifications,
                    and negotiation support to ensure a seamless and effective
                    hiring process.
                </Typography>
                <Typography variant="body1" paragraph>
                    Our services extend beyond placement to encompass a
                    comprehensive suite of recruitment solutions, including
                    direct placement, entry-level recruitment, and executive
                    search. We provide rigorous pre-screening, reference checks,
                    and negotiation support to ensure a seamless and effective
                    hiring process.
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom mt={4}>
                    What we offer?
                </Typography>
                <Typography variant="body1" component="ul" paragraph>
                    <li>&nbsp;&nbsp;• &nbsp;&nbsp;Direct Placement</li>
                    <li>&nbsp;&nbsp;• &nbsp;&nbsp;Executive Search</li>
                    <li>&nbsp;&nbsp;• &nbsp;&nbsp;Industry Expertise</li>
                    <li>
                        &nbsp;&nbsp;• &nbsp;&nbsp;Extensive Candidate Database
                    </li>
                    <li>
                        &nbsp;&nbsp;• &nbsp;&nbsp;Recruitment on Client’s
                        Payroll
                    </li>
                    <li>&nbsp;&nbsp;• &nbsp;&nbsp;Employer Branding Support</li>
                    <li>
                        &nbsp;&nbsp;• &nbsp;&nbsp;Candidate Experience
                        Management
                    </li>
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom mt={4}>
                    Why Partner with Us?
                </Typography>
                <Typography variant="body1" component="ul" paragraph>
                    <li>
                        <strong>Client-Focused Solutions:</strong> We prioritize
                        understanding your business at its core, allowing us to
                        tailor our recruitment strategies to deliver candidates
                        who not only possess the required technical expertise
                        but also align with your organizational culture.
                    </li>
                    <li>
                        <strong>Experienced Professionals:</strong> Our team
                        comprises seasoned recruitment experts with extensive
                        market knowledge and a commitment to excellence. We are
                        dedicated to providing efficient, reliable, and
                        transparent services, ensuring you receive the highest
                        quality candidates for your evolving business needs.
                    </li>
                    <li>
                        <strong>Extensive Network:</strong> Our broad network
                        and rich candidate database allow us to swiftly and
                        effectively match the right talent with the right
                        opportunities, ensuring optimal results for your
                        business.
                    </li>
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom mt={4}>
                    Engage with Us
                </Typography>
                <Typography variant="body1" paragraph>
                    Whether your needs involve filling a critical role or
                    building a high-performing team, Phoenix Consultant is your
                    strategic partner in achieving sustained business success.
                    We are committed to delivering talent solutions that propel
                    your organization forward.
                </Typography>
                <Typography variant="body1" paragraph>
                    Trust Phoenix Consultant to provide the talent that powers
                    your business.
                </Typography>
            </Box>
        </Container>
    );
};

export default AboutPC;

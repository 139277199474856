import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
    Grid,
    Typography,
    Button,
    Box,
    Card,
    CardContent,
} from "@mui/material";
import { Zoom } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Achievements from "./Achievements";
import image from "../../src/Art/aboutusimg.jpg";
import backgroundVideo from "../../src/Art/bgabout1.mp4";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        minHeight: "70vh",
        overflow: "hidden",
    },
    image: {
        width: "100%",
        height: "auto",
        borderRadius: 1,
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        transition: "transform 5s ease-in-out",
        "&:hover": {
            transform: "translateY(-10px)",
        },
    },
    button: {
        marginTop: 2,
    },
    videoBackground: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "70vh",
        overflow: "hidden",
        zIndex: 0,
        "& video": {
            minWidth: "100%",
            minHeight: "100%",
            width: "100%",
            height: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
    },
}));

const About = () => {
    let navigate = useNavigate();
    const classes = useStyles();
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const handleLearnMoreClick = () => {
        navigate("/about-us");
    };

    return (
        <div className={classes.root}>
            <div className={classes.videoBackground}>
                <video autoPlay loop muted>
                    <source src={backgroundVideo} type="video/mp4" />
                </video>
            </div>
            <Box
                sx={{
                    position: "relative",
                    zIndex: 1,
                    padding: "2rem",
                    mt: 15,
                }}
            >
                <Zoom in={inView} timeout={500}>
                    <Card
                        ref={ref}
                        variant="elevation"
                        elevation={0}
                        sx={{
                            // width: "100%",
                            // height: "75%",
                            backgroundColor: "#00000000",
                            color: "white",
                        }}
                        initial="hidden"
                        animate={controls}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: 100 },
                        }}
                        transition={{ duration: 0.6 }}
                    >
                        <CardContent>
                            <Grid
                                container
                                spacing={4}
                                alignContent="center"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    sx={{ display: "flex" }}
                                >
                                    <Typography
                                        variant="h1"
                                        gutterBottom
                                        fontFamily={"DM Serif Display"}
                                        fontWeight={"bold"}
                                        sx={{
                                            textShadow:
                                                "4px 4px 5px rgba(0,0,0,255)",
                                            color: "white",
                                            fontSize: {
                                                xs: "10px",
                                                sm: "40px",
                                                md: "40px",
                                                lg: "50px",
                                                xl: "50px",
                                            },
                                        }}
                                    >
                                        About Us
                                    </Typography>
                                    {/* <Typography variant="h3" paragraph sx={{}}>
                                        Phoenix Consultant stands as a premier provider of strategic recruitment solutions, with a legacy of excellence spanning over two decades. Established in <span style={{ color: '#EC4300' }}>1992</span>, we have earned our reputation as a trusted leader in the recruitment industry, dedicated to connecting top-tier talent with leading organizations.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        elevation={20}
                                        sx={{
                                            color: '#000',
                                            fontWeight: '10',
                                            backgroundColor: 'white',
                                            mt: 8,
                                            "&:hover": { backgroundColor: '#EC4300' },
                                        }}
                                        onClick={handleLearnMoreClick}
                                    >
                                        Learn More
                                    </Button> */}
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography
                                        variant="h3"
                                        paragraph
                                        sx={{
                                            // mt: 5,
                                            textShadow:
                                                "4px 4px 5px rgba(0,0,0,255)",
                                            fontSize: {
                                                xs: "5px",
                                                sm: "14px",
                                                md: "18px",
                                                lg: "22px",
                                                xl: "28px",
                                            },
                                        }}
                                    >
                                        Phoenix Consultant stands as a premier
                                        provider of strategic recruitment
                                        solutions, with a legacy of excellence
                                        spanning over two decades. Established
                                        in{" "}
                                        <span
                                            style={{
                                                color: "#EC4300",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            1992
                                        </span>
                                        , we have earned our reputation as a
                                        trusted leader in the recruitment
                                        industry, dedicated to connecting
                                        top-tier talent with leading
                                        organizations.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        className={classes.button}
                                        elevation={20}
                                        sx={{
                                            color: "#000",
                                            fontWeight: "10",
                                            backgroundColor: "white",
                                            // mt: 2,
                                            "&:hover": {
                                                backgroundColor: "#EC4300",
                                                color: "white",
                                            },
                                        }}
                                        onClick={handleLearnMoreClick}
                                    >
                                        Learn More
                                    </Button>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <Box
                                        component='img'
                                        src={image}
                                        alt="About Us"
                                        className={classes.image}
                                        sx={{ width: '100%', borderRadius: 1, mt: 5 }}
                                    />
                                </Grid> */}
                            </Grid>
                        </CardContent>
                    </Card>
                </Zoom>
                {/* <Achievements /> */}
            </Box>
        </div>
    );
};

export default About;

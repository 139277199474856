import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Zoom,
    Box,
    Fade,
    Slide,
} from "@mui/material";
import CountUp from "react-countup";
import bgImage from "../../src/Art/bgworld.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 4,
        zIndex: 1,
        // backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
            transform: "translateY(-10px)",
        },
    },
    icon: {
        fontSize: 48,
        marginBottom: 2,
    },
}));

const achievements = [
    { title: 30, description: "Years in Recruitment Industry", icon: "🚀" },
    {
        title: 2500,
        description: "Candidates Successfully Recruited",
        icon: "🚀",
    },
    { title: 110, description: "Satisfied Clients & Counting", icon: "🌐" },
    { title: 10000, description: "Candidates Interviewed", icon: "📊" },
];

const Achievements = () => {
    const classes = useStyles();
    const [animationTriggered, setAnimationTriggered] = useState(false);
    const achievementsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !animationTriggered) {
                    setAnimationTriggered(true);
                }
            },
            { threshold: 0.5 }
        );

        if (achievementsRef.current) {
            observer.observe(achievementsRef.current);
        }

        return () => {
            if (achievementsRef.current) {
                observer.unobserve(achievementsRef.current);
            }
        };
    }, [animationTriggered]);

    return (
        <div className={classes.root} ref={achievementsRef}>
            <Box
                sx={{
                    height: "70vh",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    overflow: "hidden",
                    padding: "2rem",
                }}
            >
                {/* <Typography variant="h1" align="center" gutterBottom color="orange" sx={{}}>
                    Showcase
                </Typography> */}
                <Typography
                    variant="h2"
                    gutterBottom
                    fontFamily={"DM Serif Display"}
                    fontWeight={"normal"}
                    sx={{
                        textShadow: "4px 4px 5px rgba(0,0,0,255)",
                        color: "white",
                        mt: -2,
                        ml: 6,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: {
                            xs: "25px",
                            sm: "30px",
                            md: "35px",
                            lg: "40px",
                            xl: "50px",
                        },
                    }}
                >
                    Our Milestones, Your Success
                </Typography>
                <Grid container spacing={4} sx={{ mt: 8 }}>
                    {achievements.map((achievement, index) => (
                        <Grid item xs={12} sm={4} md={3} key={index}>
                            <Zoom in={true} timeout={(index + 1) * 500}>
                                <Card
                                    className={classes.card}
                                    variant="elevation"
                                    elevation={0}
                                    sx={{
                                        backgroundColor: "#00000000",
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            variant="h1"
                                            component="h1"
                                            gutterBottom
                                            color="#EC4300"
                                            fontWeight={"bold"}
                                            sx={{
                                                fontSize: {
                                                    xs: "15px",
                                                    sm: "25px",
                                                    md: "30px",
                                                    lg: "35px",
                                                    xl: "40px",
                                                },
                                            }}
                                        >
                                            {animationTriggered ? (
                                                <CountUp
                                                    start={0}
                                                    end={achievement.title}
                                                    duration={2.5}
                                                />
                                            ) : (
                                                achievement.title
                                            )}
                                            +
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            color="white"
                                            fontFamily={"Alice"}
                                            sx={{
                                                fontSize: {
                                                    xs: "7px",
                                                    sm: "15px",
                                                    md: "20px",
                                                    lg: "25px",
                                                    xl: "30px",
                                                },
                                            }}
                                        >
                                            {achievement.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Zoom>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
};

export default Achievements;

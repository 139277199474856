import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton, makeStyles, Stack } from "@mui/material";

// let useStyles = makeStyles((theme) => {
//     icon: {
//         color: "white";
//         backgroundColor: "black";
//     }
// });

export default function SocialIcons(props) {
    // const classes = useStyles({ color: "#E1306C" });

    return (
        <Stack direction="row" spacing={0}>
            {props.insta && (
                <IconButton
                    aria-label="Instagram"
                    onClick={() => {
                        window.open(
                            `https://www.instagram.com/${props.instaUsername}`
                        );
                    }}
                >
                    <InstagramIcon fontSize="large" />
                </IconButton>
            )}
            {props.linkedin && (
                <IconButton
                    aria-label="LinkedIn"
                    onClick={() => {
                        window.open(
                            `https://www.linkedin.com/${props.linkedinUsername}`
                        );
                    }}
                >
                    <LinkedInIcon fontSize="large" />
                </IconButton>
            )}
        </Stack>
    );
}

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import slide_image_1 from "../Art/img_1.jpg";
import slide_image_2 from "../Art/img_2.jpg";
import slide_image_3 from "../Art/img_3.jpg";
import slide_image_4 from "../Art/img_4.jpg";
import slide_image_5 from "../Art/img_5.jpg";
import slide_image_6 from "../Art/img_6.jpg";
import slide_image_7 from "../Art/img_7.jpg";

import slide_vid1 from "../Art/servicevideos/agrochemicalIndustries.mp4";
import slide_vid2 from "../Art/servicevideos/Ancillary.mp4";
import slide_vid3 from "../Art/servicevideos/automobiles.mp4";
import slide_vid4 from "../Art/servicevideos/BankingAndFinance.mp4";
import slide_vid5 from "../Art/servicevideos/ChemicalIndustries.mp4";
// import slide_vid6 from "../Art/servicevideos/vid6.mp4";
import slide_vid7 from "../Art/servicevideos/ConstructionEquipmentManufacturers.mov";
import slide_vid8 from "../Art/servicevideos/ElectricalandElectronicComponentsManufacturer.mp4";
import slide_vid9 from "../Art/servicevideos/ItIndustry.mp4";
import slide_vid10 from "../Art/servicevideos/Manufacturing.mp4";
import slide_vid11 from "../Art/servicevideos/SteelAndForgingIndustry.mp4";
import slide_vid12 from "../Art/servicevideos/healthcare.mp4";
import slide_vid13 from "../Art/servicevideos/educationsector.mp4";

import { delay } from "framer-motion";
import { Box, Typography } from "@mui/material";

function Services() {
    let images = [
        slide_image_1,
        slide_image_2,
        slide_image_3,
        slide_image_4,
        slide_image_5,
        slide_image_6,
        slide_image_7,
    ];

    let videos = [
        ["Agrochemical Industries", slide_vid1],
        ["Ancillary Industries", slide_vid2],
        ["Automobile Industries", slide_vid3],
        ["Banking & Finance", slide_vid4],
        ["Chemical Industries", slide_vid5],
        ["Construction Equipment Manufacturers", slide_vid7],
        ["Electrical & Electronic Components Manufacturer", slide_vid8],
        ["IT Industry", slide_vid9],
        ["Manufacturing", slide_vid10],
        ["Steel & Forging Industry", slide_vid11],
        ["Healthcare Industry", slide_vid12],
        ["Education Sector", slide_vid13],
    ];

    return (
        <Box
            sx={{
                height: "70vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                padding: "4rem",
                // backgroundColor: "white",
            }}
        >
            <div className="container">
                <h1
                    className="heading"
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "2rem",
                        marginBottom: "2rem",
                        fontSize: {
                            xs: "25px",
                            sm: "30px",
                            md: "35px",
                            lg: "40px",
                            xl: "50px",
                        },
                    }}
                >
                    Industry Focus
                </h1>
                <h4
                    style={{
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        paddingBottom: "4rem",
                        textAlign: "center",
                        fontSize: {
                            xs: "15px",
                            sm: "25px",
                            md: "30px",
                            lg: "35px",
                            xl: "40px",
                        },
                    }}
                >
                    We specialize in providing expert recruitment solutions
                    across a diverse range of industries.
                    <br />
                    Our deep industry knowledge and extensive candidate network
                    enable us to meet the unique needs of leading companies.
                </h4>
                <Swiper
                    xl={12}
                    sm={3}
                    md={6}
                    effect="coverflow"
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView="1.68"
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 400,
                        modifier: 1,
                    }}
                    pagination={{ el: ".swiper-pagination", clickable: true }}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                        clickable: true,
                    }}
                    modules={[EffectCoverflow, Navigation, Autoplay]}
                    className="swiper-container"
                    spaceBetween={0}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                >
                    {videos.map((video, ind) => {
                        return (
                            <SwiperSlide key={ind}>
                                {/* <Box
                                    component="video"
                                    src={video}
                                    alt="img"
                                ></Box> */}
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    style={{ height: "40vh" }}
                                >
                                    <source src={video[1]} type="video/mp4" />
                                </video>
                                <Typography
                                    variant="h4"
                                    color="white"
                                    // width="31rem"
                                    // display="flex"
                                    // justifyContent="center"
                                >
                                    {video[0]}
                                </Typography>
                            </SwiperSlide>
                        );
                    })}

                    <div className="slider-controller">
                        <div className="swiper-button-prev slider-arrow">
                            <ion-icon name="arrow-back-outline"></ion-icon>
                        </div>
                        <div className="swiper-button-next slider-arrow">
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </Swiper>
            </div>
        </Box>
    );
}

export default Services;

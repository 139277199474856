import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
} from "react-router-dom";
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Card,
    CardMedia,
    Fade,
    Box,
    Slide,
    Grow,
} from "@mui/material/";
import Home from "./Components/HomeNew";
import About from "./Components/About";
import Services from "./Components/Services";
import Portfolio from "./Components/Portfolio";
import Contact from "./Components/Contact";
import logo from "../src/Art/logo1.png";
import SlidingText from "./Components/StylingText";
import LegacyComponent from "./Components/LegacyComponent";
import Achievements from "./Components/Achievements";
import CreditsAppBar from "./Components/CreditsAppBar";
import AboutPC from "./Components/AboutPC";
import NavBar from "./Components/navbar";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import theme from "./theme";

function App() {
    const location = useLocation();

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <Box sx={{ backgroundColor: "Black" }}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                // <Fade direction="up" in={location.pathname === '/'} mountOnEnter unmountOnExit>
                                <div>
                                    <Home />
                                    <LegacyComponent />
                                    <About id="about" />
                                    <Achievements id="achievements" />
                                    <Services />
                                    <Contact />
                                    <CreditsAppBar />
                                </div>
                                // </Fade>
                            }
                        />
                        <Route
                            path="/about-us"
                            element={
                                <div>
                                    <NavBar />
                                    <AboutPC />
                                    <CreditsAppBar />
                                </div>
                            }
                        />
                        <Route
                            path="/services"
                            element={
                                <div>
                                    <NavBar />
                                    <Services />
                                    <CreditsAppBar />
                                </div>
                            }
                        />
                        <Route
                            path="/portfolio"
                            element={
                                <div>
                                    <NavBar />
                                    <Achievements />
                                    <CreditsAppBar />
                                </div>
                            }
                        />
                        <Route
                            path="/contact"
                            element={
                                <div>
                                    <NavBar />
                                    <Contact />
                                    <CreditsAppBar />
                                </div>
                            }
                        />
                    </Routes>
                </Box>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;

// Home.js
import React, { useState } from "react";
import { Typography, Box, Button, Grid, IconButton } from "@mui/material/";
import "./Home.css";
import { AppBar, Toolbar, Container } from "@mui/material";
import CustomButton from "./CustonButton";
import { styled } from "@mui/system";
import backgroundVideo from "../../src/Art/pro.mp4";
import NavBar from "./navbar";
import LookingForTalent from "./LookingForTalent";
import CloseIcon from "@mui/icons-material/Close";
import FindMeJob from "./FindMeJob";

const StyledButton = styled(Button)({
    backgroundColor: "black",
    color: "white",
    "&:hover": {
        backgroundColor: "#333",
    },
});

function Home() {
    const [showFormOne, setShowFormOne] = useState(false);
    const [showFormTwo, setShowFormTwo] = useState(false);

    const handleLookingForTalentClick = () => {
        setShowFormOne(true);
    };

    const handleFindMeJob = () => {
        setShowFormTwo(true);
    };

    const handleCloseForm = () => {
        setShowFormOne(false);
        setShowFormTwo(false);
    };

    return (
        <div className="home">
            <video autoPlay loop muted style={{ height: "70vh" }}>
                <source src={backgroundVideo} type="video/mp4" />
            </video>
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "70vh",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    color: "white",
                    textAlign: "left",
                    paddingLeft: "0px",
                }}
            >
                <NavBar />
                <Box
                    sx={{
                        mt: "35rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                    className="maintext"
                >
                    <Typography
                        className="content-heading animated-text maintextword"
                        variant="h1"
                        sx={{
                            padding: "2rem",
                            my: "1.25rem",
                            fontSize: {
                                xs: "20px",
                                sm: "30px",
                                md: "40px",
                                lg: "55px",
                                xl: "70px",
                            },
                        }}
                    >
                        Where
                    </Typography>
                    <Typography
                        className="content-heading animated-text maintextword"
                        variant="h1"
                        sx={{
                            padding: "2rem",
                            my: "1.25rem",
                            fontSize: {
                                xs: "20px",
                                sm: "30px",
                                md: "40px",
                                lg: "55px",
                                xl: "70px",
                            },
                        }}
                    >
                        Experience
                    </Typography>
                    <Typography
                        className="content-heading animated-text maintextword"
                        variant="h1"
                        sx={{
                            padding: "2rem",
                            my: "1.25rem",
                            fontSize: {
                                xs: "20px",
                                sm: "30px",
                                md: "40px",
                                lg: "55px",
                                xl: "70px",
                            },
                        }}
                    >
                        Meets
                    </Typography>
                    <Typography
                        sx={{ display: "flex", padding: "3rem 2rem" }}
                        className="maintextword"
                    >
                        <Typography
                            className="content-heading animated-text"
                            variant="h1"
                            sx={{
                                fontSize: {
                                    xs: "20px",
                                    sm: "30px",
                                    md: "40px",
                                    lg: "55px",
                                    xl: "70px",
                                },
                            }}
                        >
                            Opportunity
                        </Typography>
                        <Typography
                            className="content-heading animated-text"
                            variant="h1"
                            sx={{
                                color: "#EC4300",
                                fontSize: {
                                    xs: "20px",
                                    sm: "30px",
                                    md: "40px",
                                    lg: "55px",
                                    xl: "70px",
                                },
                            }}
                        >
                            .
                        </Typography>
                    </Typography>
                </Box>
            </Box>
            <Grid justifyContent="flex-end">
                <Button
                    sx={{
                        color: "#fff",
                        margin: "1rem",
                        "&:hover": { backgroundColor: "#FE6D15F7" },
                    }}
                ></Button>
                <Button
                    sx={{
                        color: "#fff",
                        margin: "1rem",
                        "&:hover": { backgroundColor: "#FE6D15F7" },
                    }}
                ></Button>
            </Grid>
            <Container maxWidth="s" sx={{ mt: 35 }}>
                <Grid container spacing={1}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                        }}
                    >
                        <StyledButton
                            variant="contained"
                            sx={{
                                mt: {
                                    xs: 8,
                                    sm: 7,
                                    md: 6,
                                    lg: 5,
                                    xl: 4,
                                },
                                mb: {
                                    xs: 4,
                                    sm: 5,
                                    md: 6,
                                    lg: 7,
                                    xl: 8,
                                },
                                width: {
                                    xs: 150,
                                    sm: 200,
                                    md: 250,
                                    lg: 300,
                                    xl: 350,
                                },
                                height: {
                                    xs: 35,
                                    sm: 45,
                                    md: 55,
                                    lg: 65,
                                    xl: 75,
                                },
                                "&:hover": { backgroundColor: "#FE6D15F7" },
                                fontSize: {
                                    xs: 11,
                                    sm: 15,
                                    md: 20,
                                    lg: 25,
                                    xl: 25,
                                },
                            }}
                            onClick={handleLookingForTalentClick}
                            className="buttons"
                        >
                            Looking for Talent
                        </StyledButton>
                        <StyledButton
                            variant="contained"
                            sx={{
                                mb: {
                                    xs: 4,
                                    sm: 5,
                                    md: 6,
                                    lg: 7,
                                    xl: 8,
                                },
                                width: {
                                    xs: 150,
                                    sm: 200,
                                    md: 250,
                                    lg: 300,
                                    xl: 350,
                                },
                                height: {
                                    xs: 35,
                                    sm: 45,
                                    md: 55,
                                    lg: 65,
                                    xl: 75,
                                },
                                "&:hover": { backgroundColor: "#FE6D15F7" },
                                fontSize: {
                                    xs: 11,
                                    sm: 15,
                                    md: 20,
                                    lg: 25,
                                    xl: 25,
                                },
                            }}
                            onClick={handleFindMeJob}
                            className="buttons"
                        >
                            Find me a Job
                        </StyledButton>
                    </Grid>
                </Grid>
            </Container>
            {showFormOne && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        padding: "2rem",
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                        maxWidth: "500px",
                        width: "100%",
                        zIndex: 1,
                    }}
                >
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: "0.5rem",
                            right: "0.5rem",
                            color: "gray",
                        }}
                        onClick={handleCloseForm}
                    >
                        <CloseIcon />
                    </IconButton>
                    <LookingForTalent />
                </Box>
            )}
            {showFormTwo && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        padding: "2rem",
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
                        maxWidth: "500px",
                        width: "100%",
                        zIndex: 1,
                    }}
                >
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: "0.5rem",
                            right: "0.5rem",
                            color: "gray",
                        }}
                        onClick={handleCloseForm}
                    >
                        <CloseIcon />
                    </IconButton>
                    <FindMeJob />
                </Box>
            )}
        </div>
    );
}

export default Home;

import React, { useState, useRef } from "react";
import {
    Typography,
    Grid,
    Container,
    TextField,
    Button,
    Box,
    Card,
    CardContent,
    Stack,
} from "@mui/material/";
import emailjs from "emailjs-com";
import { useSnackbar } from "notistack";
import SocialIcons from "./SocialIcons";
import { Block } from "@mui/icons-material";

function Contact() {
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const formRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.init("BkfOMMxkSBMGpRDdA");

        // Send email using EmailJS
        emailjs
            .sendForm("service_l9nrfyj", "template_1jk9wlr", formRef.current)
            .then((result) => {
                console.log("Email sent successfully:", result.text);
                // Clear form fields after successful submission
                setName("");
                setEmail("");
                setMessage("");
            })
            .then(() => {
                enqueueSnackbar("Form submitted", { variant: "success" });
            })
            .catch((error) => {
                console.error("Error sending email:", error);
            });
    };

    return (
        <Box
            sx={{
                height: "70vh",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                padding: "2rem",
                mt: 10,
            }}
        >
            <Card variant="elevation" elevation={20} sx={{ minHeight: "50vh" }}>
                <Container sx={{ mt: 6 }}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" gutterBottom>
                                Contact Us
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Ready to take your recruitment to the next
                                level? Get in touch with us today and let's
                                discuss how we can help you find the best talent
                                for your organization.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                <h3>Office Address</h3>
                                Office no. 11, Sai Sharan Apartment, <br /> Near
                                Bhide Hospital, Savedi Naka, <br /> Ahmednagar{" "}
                                <br />
                                414003 <br />
                                <br />
                                <Typography
                                    variant="h6"
                                    sx={{ fontWeight: "bold", mt: 1 }}
                                >
                                    Email:
                                </Typography>
                                partnerships@phoenixconsultant.in
                                <br />
                                <br />
                                {/* Contact:
                                <br /> XXXXXXXX09
                                <br />
                                <br /> */}
                                <div
                                    style={{
                                        display: "flex",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "bold", mt: 1.1 }}
                                    >
                                        Connect us:
                                    </Typography>
                                    <SocialIcons
                                        // insta
                                        linkedin
                                        instaUsername=""
                                        linkedinUsername="company/phoenix-consultant32"
                                    />
                                </div>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            md={4}
                            xl={6}
                            minHeight="25vh"
                            sx={{
                                display: {
                                    xs: "none",
                                    sm: "none",
                                    md: "block",
                                    lg: "block",
                                    xl: "block",
                                },
                            }}
                        >
                            <Card elevation={5}>
                                <Box
                                    component="form"
                                    onSubmit={handleSubmit}
                                    ref={formRef}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        // "& > *": {
                                        //     marginBottom: 2,
                                        // },
                                    }}
                                >
                                    <TextField
                                        label="Name"
                                        variant="standard"
                                        sx={{ mt: 2, margin: "1rem" }}
                                        required
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        name="user_name"
                                    />
                                    <TextField
                                        label="Email"
                                        variant="standard"
                                        type="email"
                                        sx={{ mt: 2, margin: "1rem" }}
                                        required
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        name="user_email"
                                    />
                                    <TextField
                                        label="Message"
                                        variant="standard"
                                        multiline
                                        rows={4}
                                        sx={{ mt: 2, margin: "1rem" }}
                                        required
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                        name="message"
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            backgroundColor: "black",
                                            "&:hover": {
                                                backgroundColor: "#FE6D15F7",
                                            },
                                        }}
                                    >
                                        Send Message
                                    </Button>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Card>
        </Box>
    );
}

export default Contact;
